import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {Spin, Button, Table, Modal, Row, Col, Pagination} from 'antd';
import {downloadCsv} from '../../utils/downloadCsv';
import {request} from '../../utils/HttpClient';
import getQueryString from '../../utils/getQueryString';
import {
  getDashboardHasFetched,
  getDashboardIsFetching,
  getDashboardShouldFetch,
  getDashboardStages,
  getDashboardStatuses,
  DashboardFilterData,
  ProductCategoryFilterData,
  ProductSubCategoryFilterData,
  ModelVariantFilterData,
  getDownloadedData,
  isFetchingDownloadData,
  isFetchingTableData,
  getFetchedTableData,
  DealersSaleRepresentativeData,
} from '../../modules/dashboard/selectors';
import StageWaterfall from './StageWaterfall';
import MainLayout from '../../layouts/MainLayout';
import {
  initiateFetchDashboard,
  getDashboardFilterData,
  getProductCategoryFilterData,
  getProductSubCategoryFilterData,
  getModelFilterData,
  getDealerSalesRepresentativeFilterData,
  resetFilterData,
  getDownloadData,
  getTableData,
} from '../../modules/dashboard/actions';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {API_URL} from '../../constants/envVariables';
import COLORS from '../../constants/style/colors';
import HomeFilterBox from '../common/HomeFilter';
import {opportunitiesColumns} from '../../data/opportunitiesColumns';
import {getSelfDetails} from '../../modules/userDetails/selector';
import {
  RESET_SAlESREPRESENTATIVE,
  RESET_MODEL,
  RESET_PRODUCTSUBCATEGORY,
} from '../../modules/dashboard/constants';
import OpportunityModalForm from '../common/OpportunityModal';
import {getTerritoryFilterData} from '../../modules/territoryFilter/selectors';
import {getTerritoryDetails} from '../../modules/territoryFilter/action';
import {MONTHS} from '../../constants/labels';
// import {getDropdownValues} from '../../modules/dropdowns/action';

const Home = () => {
  // Redux
  const dispatch = useDispatch();

  const dashboardHasFetched = useSelector(getDashboardHasFetched);
  const dashboardIsFetching = useSelector(getDashboardIsFetching);
  const dashboardShouldFetch = useSelector(getDashboardShouldFetch);
  const territoryFilterData = useSelector(getTerritoryFilterData);
  const [filterChanged, setfilterChanged] = useState(false);
  const [tableData, setTableData] = useState([]);
  const stages = useSelector(getDashboardStages);
  const statuses = useSelector(getDashboardStatuses);
  const filterData = useSelector(DashboardFilterData);
  const authUser = useSelector(getAuthUserData);
  const detailsData = useSelector(getFetchedTableData);
  const isFetchingDetailsData = useSelector(isFetchingTableData);
  const downloadData = useSelector(getDownloadedData);
  const isFetchingDownloadRequest = useSelector(isFetchingDownloadData);

  const [zones, setZones] = useState('Zone');
  const [branches, setBranches] = useState('Branch');
  const [dealerShips, setDealerShips] = useState('Dealership');
  const [salesRepresentatives, setSalesRepresentatives] = useState(
    'Sales Representative',
  );
  const [segment, setSegment] = useState('Segment');
  const [subSegment, setSubSegment] = useState('Sub Segment');
  // const [classValue, setclassValue] = useState('Class');
  const [model, setModel] = useState('Model');
  const [keyAccount, setkeyAccount] = useState('Key Account');
  const [customerType, setCustomerType] = useState('Customer Type');
  const [application, setApplication] = useState('Application Type');
  const [financier, setFinancier] = useState('Financier');
  const [origin, setOrigin] = useState('Origin');
  const [zonesData, setZonesData] = useState([]);

  const [branchData, setBranchData] = useState([]);

  const [dealersData, setDealersData] = useState([]);
  const [valueData, setValueData] = useState('');
  const [dropdownOptions, setDropDownOptions] = useState(MONTHS);
  const [selectedOption, setSelectedOption] = useState('');
  const [downloadDataClicked, setDownloadDataClicked] = useState(false);
  const productCategoryData = useSelector(ProductCategoryFilterData);
  const productSubCategoryData = useSelector(ProductSubCategoryFilterData);
  const modelvariantData = useSelector(ModelVariantFilterData);
  const user = useSelector(getSelfDetails);
  const salesRepresentativesData = useSelector(DealersSaleRepresentativeData);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSizeNew, setPageSizeNew] = useState(10);
  const [stageClicked, setStageClicked] = useState(false);
  const [config, setConfig] = useState({
    pagination: {
      pageSizeOptions: ['10', '20', '50'],
      showSizeChanger: true,
      defaultPageSize: '10',
    },
  });

  const formatDate = (date, upper = true) => {
    const options = {year: 'numeric', month: 'long'};
    let formattedDate = date.toLocaleDateString(undefined, options);
    if (!upper) {
      options.month = 'short';
      formattedDate = date.toLocaleDateString(undefined, options);
      return formattedDate;
    }
    return formattedDate.toUpperCase();
  };

  const setRelevantMonths = () => {
    let relevantMonths = [];
    for (let i = 6; i >= 1; i--) {
      const currentDate = new Date().getMonth();
      const prevMonth = new Date();
      prevMonth.setMonth(currentDate - i);
      relevantMonths.push({
        value: formatDate(prevMonth),
        label: formatDate(prevMonth, false),
      });
    }

    for (let i = 0; i <= 3; i++) {
      const currentDate = new Date().getMonth();
      const nextMonth = new Date();
      nextMonth.setMonth(currentDate + i);
      relevantMonths.push({
        value: formatDate(nextMonth),
        label: formatDate(nextMonth, false),
      });
    }
    console.log(relevantMonths);
    setDropDownOptions(relevantMonths);
  };

  useEffect(() => {
    setRelevantMonths();
  }, []);

  useEffect(() => {
    if (territoryFilterData) {
      setZonesData(territoryFilterData.zones);
    }
  }, [territoryFilterData]);

  // useEffect(() => {
  //   dispatch(getDropdownValues());
  // }, [dispatch]);

  const onZoneChange = (value) => {
    setZones(value);
    setfilterChanged(true);
    setBranchData(
      territoryFilterData.branches.filter((item) => item.zoneCode === value),
    );
    setBranches('Branch');
    setDealerShips('Dealership');
    setSalesRepresentatives('Sales Representative');
    dispatch(resetFilterData(RESET_SAlESREPRESENTATIVE));
    setDealersData([]);
    dispatch(getDealerSalesRepresentativeFilterData(value, 'zone'));
  };

  const onBranchChange = (value) => {
    setBranches(value);
    setDealersData(
      territoryFilterData.dealerships.filter(
        (item) => item.branchCode === value,
      ),
    );
    setfilterChanged(true);
    dispatch(resetFilterData(RESET_SAlESREPRESENTATIVE));
    setDealerShips('Dealership');
    setSalesRepresentatives('Sales Representative');
    dispatch(getDealerSalesRepresentativeFilterData(value, 'branch'));
  };
  const onSalesRepresentativesChange = (value) => {
    setSalesRepresentatives(value);
    setfilterChanged(true);
  };

  const onDealershipsChange = (value) => {
    dispatch(getDealerSalesRepresentativeFilterData(value));
    setDealerShips(value);
    setfilterChanged(true);
    setSalesRepresentatives('Sales Representative');
  };
  const onRefreshTerritoryClick = () => {
    setZones('Zone');
    setDealerShips('Dealership');
    setSalesRepresentatives('Sales Representative');
    setBranches('Branch');
    setBranchData([]);
    setDealersData([]);
    setfilterChanged(true);
    dispatch(resetFilterData(RESET_SAlESREPRESENTATIVE));
    setFunnelFilterClicked(false);
  };
  const onSegmentChange = (value) => {
    setSegment(value);
    dispatch(getProductSubCategoryFilterData(value));
    setfilterChanged(true);
  };
  const onSubSegmentChange = (value) => {
    setSubSegment(value);
    dispatch(getModelFilterData(value, segment));
    setfilterChanged(true);
  };
  // const onClassChange = () => {};
  const onModelChange = (value) => {
    setModel(value);
    setfilterChanged(true);
  };
  const onRefreshProductClick = () => {
    setSegment('Segment');
    setSubSegment('Sub Segment');
    setModel('Model');
    setfilterChanged(true);
    dispatch(resetFilterData(RESET_MODEL));
    dispatch(resetFilterData(RESET_PRODUCTSUBCATEGORY));
    setFunnelFilterClicked(false);
  };
  const onKeyAccountChanges = (value) => {
    setkeyAccount(value);
    setfilterChanged(true);
  };
  const onCustomerTypeChange = (value) => {
    setCustomerType(value);
    setfilterChanged(true);
  };
  const onApplicationChange = (value) => {
    setApplication(value);
    setfilterChanged(true);
  };
  const onFinancierChange = (value) => {
    setFinancier(value);
    setfilterChanged(true);
  };
  const onOriginChange = (value) => {
    setOrigin(value);
    setfilterChanged(true);
  };
  const onRefreshCustomerClick = () => {
    setkeyAccount('Key Account');
    setCustomerType('Customer Type');
    setApplication('Application Type');
    setFinancier('Financier');
    setOrigin('Origin');
    setfilterChanged(true);
    setFunnelFilterClicked(false);
  };
  useEffect(() => {
    dispatch(getTerritoryDetails(authUser.accessToken));
  }, [authUser.accessToken, dispatch]);

  useEffect(() => {
    if (!productCategoryData) {
      dispatch(getProductCategoryFilterData());
    }
  }, [productCategoryData, dispatch]);

  const itemsTerritory = [
    {
      fieldId: 7,
      fieldName: 'Territory',
      fieldTitle: '',
      fieldType: 'Span',
    },
    {
      fieldId: 1,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: zonesData ? zonesData : [],
      onChange: onZoneChange,
      value: zones,
      disable: true,
    },
    {
      fieldId: 2,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: branchData ? branchData : [],
      onChange: onBranchChange,
      value: branches,
      disable: true,
    },
    {
      fieldId: 3,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: dealersData ? dealersData : [],
      onChange: onDealershipsChange,
      value: dealerShips,
      disable: true,
    },
    {
      fieldId: 4,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: salesRepresentativesData ? salesRepresentativesData : [],
      onChange: onSalesRepresentativesChange,
      value: salesRepresentatives,
    },
    {
      fieldId: 5,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Col',
    },
    {
      fieldId: 6,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Refresh',
      onClick: onRefreshTerritoryClick,
    },
  ];
  const itemsProduct = [
    {
      fieldId: 7,
      fieldName: 'Product',
      fieldTitle: '',
      fieldType: 'Span',
    },
    {
      fieldId: 1,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: productCategoryData ? productCategoryData : [],
      onChange: onSegmentChange,
      value: segment,
    },
    {
      fieldId: 2,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: productSubCategoryData ? productSubCategoryData : [],
      onChange: onSubSegmentChange,
      value: subSegment,
    },
    {
      fieldId: 4,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: modelvariantData ? modelvariantData : [],
      onChange: onModelChange,
      value: model,
    },
    {
      fieldId: 3,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Col',
    },

    {
      fieldId: 5,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Col',
    },
    {
      fieldId: 6,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Refresh',
      onClick: onRefreshProductClick,
    },
  ];
  const itemsCustomer = [
    {
      fieldId: 7,
      fieldName: 'Customer',
      fieldTitle: '',
      fieldType: 'Span',
    },
    {
      fieldId: 1,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: filterData ? filterData.keyAccount : [],
      onChange: onKeyAccountChanges,
      value: keyAccount,
    },
    {
      fieldId: 2,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: filterData ? filterData.customerTypes : [],
      onChange: onCustomerTypeChange,
      value: customerType,
    },
    {
      fieldId: 3,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: filterData ? filterData.applicationTypes : [],
      onChange: onApplicationChange,
      value: application,
    },
    {
      fieldId: 4,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: filterData ? filterData.financiers : [],
      onChange: onFinancierChange,
      value: financier,
    },
    {
      fieldId: 5,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: filterData ? filterData.origins : [],
      onChange: onOriginChange,
      value: origin,
    },
    {
      fieldId: 6,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Refresh',
      onClick: onRefreshCustomerClick,
    },
  ];

  useEffect(() => {
    if (!filterData) {
      dispatch(getDashboardFilterData());
      setFunnelFilterClicked(false);
    }
  }, [dispatch, filterData]);

  useEffect(() => {
    if (dashboardShouldFetch || filterChanged) {
      dispatch(
        initiateFetchDashboard({
          KeyAccountCode: keyAccount === 'Key Account' ? 'All' : keyAccount,
          CustomerTypeCode:
            customerType === 'Customer Type' ? 'All' : customerType,
          ApplicationTypeCode:
            application === 'Application Type' ? 'All' : application,
          FinancierCode: financier === 'Financier' ? 'All' : financier,
          OriginCode: origin === 'Origin' ? 'All' : origin,
          ProductCategoryCode: segment === 'Segment' ? 'All' : segment,
          ProductSubCategoryCode:
            subSegment === 'Sub Segment' ? 'All' : subSegment,
          ModelVariantCode: model === 'Model' ? 'All' : model,
          ZoneCode: zones === 'Zone' ? 'All' : zones,
          DealerCode: dealerShips === 'Dealership' ? 'All' : dealerShips,
          EmployeeCode:
            salesRepresentatives === 'Sales Representative'
              ? 'All'
              : salesRepresentatives,
          BranchCode: branches === 'Branch' ? 'All' : branches,
        }),
      );
      setfilterChanged(false);
    }
  }, [
    dispatch,
    dealerShips,
    zones,
    subSegment,
    segment,
    model,
    origin,
    financier,
    application,
    customerType,
    keyAccount,
    dashboardShouldFetch,
    filterChanged,
    salesRepresentatives,
    branches,
  ]);

  const [funnelFilterClicked, setFunnelFilterClicked] = useState(false);

  const handleStageClick = async (value) => {
    setValueData(value);
    setStageClicked(true);
    callApiAndDownloadCsv({
      OpportunityStageTypeSpecificMonth: selectedOption ? selectedOption : null,
      OpportunityStageType: value,
      KeyAccountCode: keyAccount === 'Key Account' ? 'All' : keyAccount,
      CustomerTypeCode: customerType === 'Customer Type' ? 'All' : customerType,
      ApplicationTypeCode:
        application === 'Application Type' ? 'All' : application,
      FinancierCode: financier === 'Financier' ? 'All' : financier,
      OriginCode: origin === 'Origin' ? 'All' : origin,
      ProductCategoryCode: segment === 'Segment' ? 'All' : segment,
      ProductSubCategoryCode: subSegment === 'Sub Segment' ? 'All' : subSegment,
      ModelVariantCode: model === 'Model' ? 'All' : model,
      ZoneCode: zones === 'Zone' ? 'All' : zones,
      DealerCode: dealerShips === 'Dealership' ? 'All' : dealerShips,
      EmployeeCode:
        salesRepresentatives === 'Sales Representative'
          ? 'All'
          : salesRepresentatives,
      BranchCode: branches === 'Branch' ? 'All' : branches,
      PageNumber: 1,
      PageSize: 10,
    });
    setFunnelFilterClicked(true);
  };

  const handleStatusClick = async (value) => {
    setValueData(value);
    setStageClicked(false);
    callApiAndDownloadCsv({
      OpportunityStageTypeSpecificMonth: selectedOption ? selectedOption : null,
      OpportunityStatus: value,
      KeyAccountCode: keyAccount === 'Key Account' ? 'All' : keyAccount,
      CustomerTypeCode: customerType === 'Customer Type' ? 'All' : customerType,
      ApplicationTypeCode:
        application === 'Application Type' ? 'All' : application,
      FinancierCode: financier === 'Financier' ? 'All' : financier,
      OriginCode: origin === 'Origin' ? 'All' : origin,
      ProductCategoryCode: segment === 'Segment' ? 'All' : segment,
      ProductSubCategoryCode: subSegment === 'Sub Segment' ? 'All' : subSegment,
      ModelVariantCode: model === 'Model' ? 'All' : model,
      ZoneCode: zones === 'Zone' ? 'All' : zones,
      DealerCode: dealerShips === 'Dealership' ? 'All' : dealerShips,
      EmployeeCode:
        salesRepresentatives === 'Sales Representative'
          ? 'All'
          : salesRepresentatives,
      BranchCode: branches === 'Branch' ? 'All' : branches,
      PageNumber: 1,
      PageSize: 10,
    });
    setFunnelFilterClicked(true);
  };

  const handleMonthChange = (value) => {
    setValueData(value);
    setSelectedOption(value);
    setStageClicked(true);
    dispatch(
      initiateFetchDashboard({
        DashboardOpportunityStageType: value,
        KeyAccountCode: keyAccount === 'Key Account' ? 'All' : keyAccount,
        CustomerTypeCode:
          customerType === 'Customer Type' ? 'All' : customerType,
        ApplicationTypeCode:
          application === 'Application Type' ? 'All' : application,
        FinancierCode: financier === 'Financier' ? 'All' : financier,
        OriginCode: origin === 'Origin' ? 'All' : origin,
        ProductCategoryCode: segment === 'Segment' ? 'All' : segment,
        ProductSubCategoryCode:
          subSegment === 'Sub Segment' ? 'All' : subSegment,
        ModelVariantCode: model === 'Model' ? 'All' : model,
        ZoneCode: zones === 'Zone' ? 'All' : zones,
        DealerCode: dealerShips === 'Dealership' ? 'All' : dealerShips,
        EmployeeCode:
          salesRepresentatives === 'Sales Representative'
            ? 'All'
            : salesRepresentatives,
        BranchCode: branches === 'Branch' ? 'All' : branches,
      }),
    );
    if (value === MONTHS[new Date().getMonth()].value) {
      callApiAndDownloadCsv({
        OpportunityStageType: value,
        KeyAccountCode: keyAccount === 'Key Account' ? 'All' : keyAccount,
        CustomerTypeCode:
          customerType === 'Customer Type' ? 'All' : customerType,
        ApplicationTypeCode:
          application === 'Application Type' ? 'All' : application,
        FinancierCode: financier === 'Financier' ? 'All' : financier,
        OriginCode: origin === 'Origin' ? 'All' : origin,
        ProductCategoryCode: segment === 'Segment' ? 'All' : segment,
        ProductSubCategoryCode:
          subSegment === 'Sub Segment' ? 'All' : subSegment,
        ModelVariantCode: model === 'Model' ? 'All' : model,
        ZoneCode: zones === 'Zone' ? 'All' : zones,
        DealerCode: dealerShips === 'Dealership' ? 'All' : dealerShips,
        EmployeeCode:
          salesRepresentatives === 'Sales Representative'
            ? 'All'
            : salesRepresentatives,
        BranchCode: branches === 'Branch' ? 'All' : branches,
        PageNumber: 1,
        PageSize: 10,
      });
    } else {
      callApiAndDownloadCsv({
        OpportunityStageType: value,
        KeyAccountCode: keyAccount === 'Key Account' ? 'All' : keyAccount,
        CustomerTypeCode:
          customerType === 'Customer Type' ? 'All' : customerType,
        ApplicationTypeCode:
          application === 'Application Type' ? 'All' : application,
        FinancierCode: financier === 'Financier' ? 'All' : financier,
        OriginCode: origin === 'Origin' ? 'All' : origin,
        ProductCategoryCode: segment === 'Segment' ? 'All' : segment,
        ProductSubCategoryCode:
          subSegment === 'Sub Segment' ? 'All' : subSegment,
        ModelVariantCode: model === 'Model' ? 'All' : model,
        ZoneCode: zones === 'Zone' ? 'All' : zones,
        DealerCode: dealerShips === 'Dealership' ? 'All' : dealerShips,
        EmployeeCode:
          salesRepresentatives === 'Sales Representative'
            ? 'All'
            : salesRepresentatives,
        BranchCode: branches === 'Branch' ? 'All' : branches,
        PageNumber: 1,
        PageSize: 10,
      });
    }
    setFunnelFilterClicked(true);
  };

  const onCurrentMonthClick = (value) => {
    setValueData(value);
    setSelectedOption(formatDate(new Date()));
    setStageClicked(true);
    dispatch(
      initiateFetchDashboard({
        DashboardOpportunityStageType: value,
        KeyAccountCode: keyAccount === 'Key Account' ? 'All' : keyAccount,
        CustomerTypeCode:
          customerType === 'Customer Type' ? 'All' : customerType,
        ApplicationTypeCode:
          application === 'Application Type' ? 'All' : application,
        FinancierCode: financier === 'Financier' ? 'All' : financier,
        OriginCode: origin === 'Origin' ? 'All' : origin,
        ProductCategoryCode: segment === 'Segment' ? 'All' : segment,
        ProductSubCategoryCode:
          subSegment === 'Sub Segment' ? 'All' : subSegment,
        ModelVariantCode: model === 'Model' ? 'All' : model,
        ZoneCode: zones === 'Zone' ? 'All' : zones,
        DealerCode: dealerShips === 'Dealership' ? 'All' : dealerShips,
        EmployeeCode:
          salesRepresentatives === 'Sales Representative'
            ? 'All'
            : salesRepresentatives,
        BranchCode: branches === 'Branch' ? 'All' : branches,
      }),
    );
    callApiAndDownloadCsv({
      OpportunityStageType: value,
      KeyAccountCode: keyAccount === 'Key Account' ? 'All' : keyAccount,
      CustomerTypeCode: customerType === 'Customer Type' ? 'All' : customerType,
      ApplicationTypeCode:
        application === 'Application Type' ? 'All' : application,
      FinancierCode: financier === 'Financier' ? 'All' : financier,
      OriginCode: origin === 'Origin' ? 'All' : origin,
      ProductCategoryCode: segment === 'Segment' ? 'All' : segment,
      ProductSubCategoryCode: subSegment === 'Sub Segment' ? 'All' : subSegment,
      ModelVariantCode: model === 'Model' ? 'All' : model,
      ZoneCode: zones === 'Zone' ? 'All' : zones,
      DealerCode: dealerShips === 'Dealership' ? 'All' : dealerShips,
      EmployeeCode:
        salesRepresentatives === 'Sales Representative'
          ? 'All'
          : salesRepresentatives,
      BranchCode: branches === 'Branch' ? 'All' : branches,
      PageNumber: 1,
      PageSize: 10,
    });
    setFunnelFilterClicked(true);
  };

  const callApiAndDownloadCsv = async (filters) => {
    const filterQueryString = getQueryString(filters);
    const url = `${API_URL}/Opportunities/MyOrgOpportunitiesDownload?${filterQueryString}`;
    dispatch(getTableData(authUser.accessToken, url));
  };

  const callDownloadCsv = async (filters) => {
    const filterQueryString = getQueryString(filters);
    const url = `${API_URL}/Opportunities/MyOrgOpportunitiesFileDownload?${filterQueryString}`;
    dispatch(getDownloadData(authUser.accessToken, url));
  };

  const shouldShowLoader = !dashboardHasFetched || dashboardIsFetching;
  const [modalData, setModalData] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTableData(detailsData ? detailsData.opportunities : []);
  }, [detailsData]);

  // const filterDataOnAccountName = (value) => {
  //   if (detailsData && value.length > 0) {
  //     setTableData(
  //       detailsData.opportunities
  //         ? detailsData.opportunities.filter((item) => {
  //             return item.accountName
  //               ? item.accountName
  //                   .trim()
  //                   .toLowerCase()
  //                   .includes(value?.trim().toLowerCase())
  //               : false;
  //           })
  //         : [],
  //     );
  //   } else {
  //     setTableData(detailsData ? detailsData.opportunities : []);
  //   }
  // };

  const getDetails = (e, record) => {
    setVisible(!visible);
    setModalData(record);
  };
  const handleCreate = () => {};

  const downloadDataDumpClick = () => {
    setDownloadDataClicked(true);
    if (stageClicked) {
      callDownloadCsv({
        OpportunityStageType: valueData,
        KeyAccountCode: keyAccount === 'Key Account' ? 'All' : keyAccount,
        CustomerTypeCode:
          customerType === 'Customer Type' ? 'All' : customerType,
        ApplicationTypeCode:
          application === 'Application Type' ? 'All' : application,
        FinancierCode: financier === 'Financier' ? 'All' : financier,
        OriginCode: origin === 'Origin' ? 'All' : origin,
        ProductCategoryCode: segment === 'Segment' ? 'All' : segment,
        ProductSubCategoryCode:
          subSegment === 'Sub Segment' ? 'All' : subSegment,
        ModelVariantCode: model === 'Model' ? 'All' : model,
        ZoneCode: zones === 'Zone' ? 'All' : zones,
        DealerCode: dealerShips === 'Dealership' ? 'All' : dealerShips,
        EmployeeCode:
          salesRepresentatives === 'Sales Representative'
            ? 'All'
            : salesRepresentatives,
        BranchCode: branches === 'Branch' ? 'All' : branches,
        PageNumber: 1,
        PageSize: 10,
      });
    } else {
      callDownloadCsv({
        OpportunityStatus: valueData,
        KeyAccountCode: keyAccount === 'Key Account' ? 'All' : keyAccount,
        CustomerTypeCode:
          customerType === 'Customer Type' ? 'All' : customerType,
        ApplicationTypeCode:
          application === 'Application Type' ? 'All' : application,
        FinancierCode: financier === 'Financier' ? 'All' : financier,
        OriginCode: origin === 'Origin' ? 'All' : origin,
        ProductCategoryCode: segment === 'Segment' ? 'All' : segment,
        ProductSubCategoryCode:
          subSegment === 'Sub Segment' ? 'All' : subSegment,
        ModelVariantCode: model === 'Model' ? 'All' : model,
        ZoneCode: zones === 'Zone' ? 'All' : zones,
        DealerCode: dealerShips === 'Dealership' ? 'All' : dealerShips,
        EmployeeCode:
          salesRepresentatives === 'Sales Representative'
            ? 'All'
            : salesRepresentatives,
        BranchCode: branches === 'Branch' ? 'All' : branches,
        PageNumber: 1,
        PageSize: 10,
      });
    }
  };

  useEffect(() => {
    if (downloadDataClicked && !isFetchingDownloadRequest) {
      if (downloadData) {
        Modal.success({
          title: downloadData.success
            ? 'Download request submitted successfully!!'
            : 'Download request failed',
          content: (
            <div>
              <p></p>
              <p>
                {downloadData.success
                  ? 'You will receive an email shortly with the opportunity list'
                  : 'Invalid Email Address or problem in sending the email !!'}
              </p>
            </div>
          ),
          onOk() {},
        });
        setDownloadDataClicked(false);
      }
    }
  }, [downloadDataClicked, isFetchingDownloadRequest, downloadData]);

  const updatedOpptyCol = [
    ...opportunitiesColumns,
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'followUpCallId',
      width: '12%',
      render: (text, record) => (
        <Button
          onClick={(e) => {
            getDetails(e, record);
          }}
          style={{
            background: '#fd560eb5',
            border: '#fd560eb5',
            fontWeight: '550',
          }}
          type="primary">
          Details
        </Button>
      ),
    },
  ];

  const pageChanged = (page, pageSize) => {
    setPageNumber(page);
    setConfig((prevState) => ({
      pagination: {
        ...prevState.pagination,
        defaultPageSize: pageSize,
        pageSize: pageSize,
      },
    }));
    setPageSizeNew(pageSize);
    if (stageClicked) {
      callApiAndDownloadCsv({
        OpportunityStageType: selectedOption ? selectedOption : valueData,
        KeyAccountCode: keyAccount === 'Key Account' ? 'All' : keyAccount,
        CustomerTypeCode:
          customerType === 'Customer Type' ? 'All' : customerType,
        ApplicationTypeCode:
          application === 'Application Type' ? 'All' : application,
        FinancierCode: financier === 'Financier' ? 'All' : financier,
        OriginCode: origin === 'Origin' ? 'All' : origin,
        ProductCategoryCode: segment === 'Segment' ? 'All' : segment,
        ProductSubCategoryCode:
          subSegment === 'Sub Segment' ? 'All' : subSegment,
        ModelVariantCode: model === 'Model' ? 'All' : model,
        ZoneCode: zones === 'Zone' ? 'All' : zones,
        DealerCode: dealerShips === 'Dealership' ? 'All' : dealerShips,
        EmployeeCode:
          salesRepresentatives === 'Sales Representative'
            ? 'All'
            : salesRepresentatives,
        BranchCode: branches === 'Branch' ? 'All' : branches,
        PageNumber: page,
        PageSize: pageSize,
      });
    } else {
      callApiAndDownloadCsv({
        OpportunityStatus: valueData,
        KeyAccountCode: keyAccount === 'Key Account' ? 'All' : keyAccount,
        CustomerTypeCode:
          customerType === 'Customer Type' ? 'All' : customerType,
        ApplicationTypeCode:
          application === 'Application Type' ? 'All' : application,
        FinancierCode: financier === 'Financier' ? 'All' : financier,
        OriginCode: origin === 'Origin' ? 'All' : origin,
        ProductCategoryCode: segment === 'Segment' ? 'All' : segment,
        ProductSubCategoryCode:
          subSegment === 'Sub Segment' ? 'All' : subSegment,
        ModelVariantCode: model === 'Model' ? 'All' : model,
        ZoneCode: zones === 'Zone' ? 'All' : zones,
        DealerCode: dealerShips === 'Dealership' ? 'All' : dealerShips,
        EmployeeCode:
          salesRepresentatives === 'Sales Representative'
            ? 'All'
            : salesRepresentatives,
        BranchCode: branches === 'Branch' ? 'All' : branches,
        PageNumber: page,
        PageSize: pageSize,
      });
    }
  };

  console.log('Selected:', selectedOption);
  return (
    <MainLayout>
      <div className="contentLayout">
        <fieldset className="contentFieldSet">
          <legend>Data Filter</legend>
          <HomeFilterBox items={itemsTerritory}></HomeFilterBox>
          <HomeFilterBox items={itemsProduct}></HomeFilterBox>
          <HomeFilterBox items={itemsCustomer}></HomeFilterBox>
        </fieldset>
      </div>
      <div className="contentLayout">
        <span className="contentHeader">Funnel View</span>
        <Container>
          {shouldShowLoader ? (
            <Spin />
          ) : (
            <MetricesContainer>
              <Item>
                <StageWaterfall
                  data={stages}
                  defaultValue={
                    selectedOption ? selectedOption : formatDate(new Date())
                  }
                  handleSelectClick={handleMonthChange}
                  onClick={handleStageClick}
                  options={dropdownOptions}
                />
              </Item>
              <Item>
                <StatusList>
                  {Object.keys(statuses).map(
                    (key) => (
                      console.log(key),
                      (
                        <StatusItem
                          background={statuses[key].background}
                          key={key}
                          onClick={() => handleStatusClick(statuses[key].code)}>
                          <StatusLabel>{statuses[key].title}</StatusLabel>
                          <StatusValue>{statuses[key].value}</StatusValue>
                        </StatusItem>
                      )
                    ),
                  )}
                </StatusList>
              </Item>
              <Item>
                <CurrentMonthButton
                  onClick={() => onCurrentMonthClick('CURRENT_MONTH')}>
                  Current Month
                </CurrentMonthButton>
              </Item>
            </MetricesContainer>
          )}
        </Container>
      </div>
      {funnelFilterClicked ? (
        <div className="contentLayout">
          <div>
            <Row>
              <Col span={4}>
                <span className="contentHeader">Opportunities</span>
              </Col>
              <Col span={8}>
                <Button
                  onClick={() => downloadDataDumpClick()}
                  style={{
                    background: '#38a169',
                    border: '#38a169',
                    fontWeight: '550',
                    float: 'left',
                    marginRight: '10px',
                  }}
                  type="primary">
                  Export Opportunities
                </Button>
              </Col>
              <Col span={12}>
                <Pagination
                  defaultCurrent={1}
                  onChange={pageChanged}
                  style={{float: 'right'}}
                  total={
                    detailsData
                      ? detailsData.optyDataCount
                        ? detailsData.optyDataCount
                        : 1
                      : 1
                  }
                />
              </Col>
            </Row>
          </div>
          <OpportunityModalForm
            data={modalData}
            onCancel={() => setVisible(false)}
            onCreate={() => handleCreate()}
            visible={visible}
          />
          <Table
            columns={updatedOpptyCol}
            dataSource={tableData}
            loading={isFetchingDetailsData || isFetchingDownloadRequest}
            {...config}
            pagination={false}
            scroll={{x: 440}}
            style={{marginTop: '20px'}}
          />
        </div>
      ) : (
        ''
      )}
    </MainLayout>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  margin: 1.5rem 5rem 0 5rem;
`;
const MetricesContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 0 2em;
`;
const Item = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const StatusList = styled.div`
  display: flex;
  flex-grow: 1;
`;
const StatusItem = styled.div`
  margin: 1em 1em;
  border-radius: 5px;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.background};
  cursor: pointer;
`;
const StatusLabel = styled.span`
  display: block;
  width: 100%;
  line-height: 18px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 16px;
`;
const StatusValue = styled.span`
  display: block;
  width: 100%;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  font-size: 32px;
  margin-top: 10px;
  margin-bottom: 16px;
`;
const CurrentMonthButton = styled(Button)`
  height: 42px;
  font-weight: 600;
  color: ${COLORS.brandColor.current};
  border-color: ${COLORS.brandColor.current};
`;

export default Home;
